<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="10">
          <v-card class="elevation-10">
            <v-toolbar color="red" dark flat>
              <v-toolbar-title>
                <span>Access Denied</span>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-title>You don't have access to this section</v-card-title>
            <v-card-text
              ><b>Your IP has been sent to an administrator. </b> If you think this is an error simply call
              {{ selectedProgram.programPhone }} or send an email to {{ selectedProgram.programEmail }}. Our customer
              care team is available between 8:30am and 5:00pm (ct) Monday through Friday to assist.<br /><br /><br />
              <v-btn color="info" @click="backToDashboard()">Close</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccessDenied",
  metaInfo: {
    title: "Access Denied"
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["selectedProgram"])
  },
  methods: {
    backToDashboard() {
      this.$router.push({ name: "dashboard" });
    }
  }
};
</script>
